import * as React from 'react';
//import { Admin, Layout, Resource } from "react-admin";
import {
    //AppLocationContext,
    Menu,
    MenuItem,
    MenuItemCategory,
    MultiLevelMenu,
    // theme,
} from "@react-admin/ra-navigation";
import { makeStyles } from "@material-ui/core/styles";
import { Box, CardContent, Typography } from "@material-ui/core";

import {
    AddLocation as AddLocationIcon,
    Business as BusinessIcon,
    Dashboard as DashboardIcon,
    Description as DescriptionIcon,
    FlashOn as FlashIcon,
    Label as LabelIcon,
    LocalAtm as LocalAtmIcon,
    Mail as MailIcon,
    PermMedia as PermMediaIcon,
    People as PeopleIcon,
    Place as PlaceIcon,
    Public as PublicIcon,
    Settings as SettingsIcon,
    ViewList as ViewListIcon,
//  as Icon,
} from '@material-ui/icons';

const useStyles = makeStyles({
    // Custom styles for the configuration item so that it appears at the very bottom of the sidebar
    configuration: {
        marginTop: "auto",
    },
});

const AdminMenu = () => {
    const classes = useStyles();

    return (
        <MultiLevelMenu variant="categories">
            <MenuItemCategory
                name="dashboard"
                to="/"
                exact
                label="Dashboard"
                icon={<DashboardIcon />}
            />
            {/* The empty filter is required to avoid falling back to the previously set filter */}
            <MenuItemCategory
                name="partners"
                // to={"/franchise_partners?filter={}"}
                label="Partner, User, Gebiete"
                icon={<PeopleIcon />}
            >
                {/* CardContent to get consistent spacings */}
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        Partner, User, Gebiete
                    </Typography>
                    <Menu>
                        <MenuItem
                            name="franchise_partners"
                            to={'/franchise_partners'}
                            label="Franchise Partners"
                            icon={<BusinessIcon />}
                        />
                        <MenuItem
                            name="users"
                            to={'/users'}
                            label="Users"
                            icon={<PeopleIcon />}
                        />
                        <MenuItem
                            name="areas"
                            to={'/areas'}
                            label="Gebiete"
                            icon={<LocalAtmIcon />}
                        />
                        <MenuItem
                            name="places"
                            to={'/places'}
                            label="Standorte"
                            icon={<PlaceIcon />}
                        />
                    </Menu>
                </CardContent>
            </MenuItemCategory>
            <MenuItemCategory
                name="marketing"
                // to={"/marketing?filter={}"}
                label="Marketing"
                icon={<FlashIcon />}
            >
                {/* CardContent to get consistent spacings */}
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Box marginRight={2}>
                            <Typography variant="h5" gutterBottom>
                                Briefwerbung
                            </Typography>
                            <Menu>
                                <MenuItem
                                    name="marketing.mail_advertising_orders"
                                    to={'/mail_advertising_orders'}
                                    label="Bestellungen"
                                    icon={<MailIcon />}
                                />
                                <MenuItem
                                    name="marketing.mail_advertising_templates"
                                    to={'/mail_advertising_templates'}
                                    label="Briefvorlagen"
                                    icon={<DescriptionIcon />}
                                />
                                <MenuItem
                                    name="marketing.mail_advertising_topics"
                                    to={'/mail_advertising_topics'}
                                    label="Themen"
                                    icon={<ViewListIcon />}
                                />
                                <MenuItem
                                    name="marketing.mail_advertising_order_statuses"
                                    to={'/mail_advertising_order_statuses'}
                                    label="Status"
                                    icon={<LabelIcon />}
                                />
                            </Menu>
                        </Box>
                        <Box>
                            <Typography variant="h5" gutterBottom>
                                Firmendaten
                            </Typography>
                            <Menu>
                                <MenuItem
                                    name="marketing.company_datas"
                                    to={'/company_datas'}
                                    label="Adressdaten"
                                    icon={<AddLocationIcon />}
                                />
                                <MenuItem
                                    name="marketing.business_sectors"
                                    to={'/business_sectors'}
                                    label="Branchen"
                                    icon={<LabelIcon />}
                                />
                            </Menu>
                        </Box>
                    </Box>
                </CardContent>
            </MenuItemCategory>
            <MenuItemCategory
                name="media_objects"
                icon={<PermMediaIcon />}
                to="/media_objects"
                label="Media Files"
            />
            <MenuItemCategory
                className={classes.configuration}
                name="configuration"
                // to="/"
                exact
                label="Configuration"
                icon={<SettingsIcon />}
            >
                {/* CardContent to get consistent spacings */}
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        Configuration
                    </Typography>
                    <Menu>
                        <MenuItem
                            name="countries"
                            to={'/countries'}
                            label="Länder"
                            icon={<PublicIcon />}
                        />
                    </Menu>
                </CardContent>
            </MenuItemCategory>
        </MultiLevelMenu>
    );
};

export default AdminMenu;