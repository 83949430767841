// BusinessSectorsList
import React from 'react'
import {
    FieldGuesser,
    ListGuesser
} from "@api-platform/admin";

export const BusinessSectorsList = (props) => {
    return (
        <ListGuesser {...props}>
            <FieldGuesser label="Primärbranche" source={"isPrimary"} />
            <FieldGuesser label="Titel" source={"title"} />
            <FieldGuesser label="Suchbegriff" source={"searchPattern"} />
            <FieldGuesser label="Beschreibung" source={"description"} />
            {/* <FieldGuesser source={"mailAdvertisingTopics"} />
            <FieldGuesser source={"mailAdvertisingOrders"} /> */}
            {/* <FieldGuesser source={"companies"} /> */}
            {/* <FieldGuesser label="Anzahl Unternehmensadressdaten" source={"numberOfCompanies"} /> */}
        </ListGuesser>
    )
}
