import React from 'react';
import { AppBar, useAuthProvider } from 'react-admin';
// import { PreferencesEditorButton } from "@react-admin/ra-no-code";
import {
    LanguageSwitcher,
    ToggleThemeButton,
} from '@react-admin/ra-preferences';
import {
    Typography,
    makeStyles
} from '@material-ui/core';

import Logo from './Logo';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});
// const useStyles = makeStyles(
//     theme => ({
//         root: {
//             position: 'relative',
//         },
//         toolbar: {},
//         menuButton: {},
//         menuButtonIconClosed: {},
//         menuButtonIconOpen: {},
//         title: {
//             flex: 1,
//             textOverflow: 'ellipsis',
//             whiteSpace: 'nowrap',
//             overflow: 'hidden',
//             paddingLeft: theme.spacing(1),
//         },
//     }),
//     {
//         name: 'RaEnterpriseAppBar',
//     }
// );

const AdminAppBar = (props) => {
    const authProvider = useAuthProvider();

    // const {languages, defaultLanguage, userMenu, ...rest} = props;
    const {userMenu, ...rest} = props;

    const languages = [{
        locale: 'de',
        name: 'Deutsch'
    }, {
        locale: 'en',
        name: 'English'
    }, {
        locale: 'at',
        name: 'Österreichisch'
    }, {
        locale: 'at-vie',
        name: 'Wienerisch'
    }];

    const defaultLanguage = 'English';

    const classes = useStyles();

    return (
        <AppBar userMenu={userMenu || !!authProvider}  {...rest}>
            {/*<AppBar userMenu={userMenu || !!authProvider} {...rest}>*/}
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <Logo/>
            <span className={classes.spacer}/>
            <ToggleThemeButton/>
            {languages && languages.length > 0 ? (
                <LanguageSwitcher
                    languages={languages}
                    defaultLanguage={defaultLanguage || languages[0].name}
                />
            ) : null}
            {props.children}
        </AppBar>
    );
};

export default AdminAppBar;
