import { CreateGuesser } from "../../form/CreateGuesser";

import {
    FileField,
    FileInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    required,
} from 'react-admin';

const validateRequired = [required()];

export const MailAdvertisingTemplateCreate = props => (
    <CreateGuesser {...props}>
        <TextInput source={"title"} validate={validateRequired} />
        <ReferenceInput
            source="topic"
            reference="mail_advertising_topics"
        >
            <SelectInput optionText={record => `${record.title} (${record.name})`} />
        </ReferenceInput>
        <ReferenceInput
            source="franchisePartner"
            reference="franchise_partners"
        >
            <SelectInput optionText={record => `${record.companyName} - ${record.firstname} ${record.lastname}`} />
        </ReferenceInput>
        {/* <InputGuesser source="mediaObject" /> */}
        <FileInput source="file">
            <FileField source="src" title="title" />
        </FileInput>
    </CreateGuesser>
);
