import { EditGuesser } from "../../form/EditGuesser";
import { InputGuesser } from "@api-platform/admin";

import {
    ReferenceInput,
    SelectInput,
    TextInput,
    required,
} from 'react-admin';

const validateRequired = [required()];

export const MailAdvertisingTemplateEdit = props => (
    <EditGuesser {...props}>
        <TextInput source={"title"} validate={validateRequired} />
        {/* <InputGuesser source={"topic"} /> */}
        <ReferenceInput
            source="topic"
            reference="mail_advertising_topics"
            allowEmpty
        >
            <SelectInput optionText={record => `${record.title} (${record.name})`} />
        </ReferenceInput>
        <ReferenceInput
            source="franchisePartner"
            reference="franchise_partners"
            allowEmpty
        >
            <SelectInput optionText={record => `${record.companyName} - ${record.firstname} ${record.lastname}`} />
        </ReferenceInput>
        <InputGuesser source={"file"} />
    </EditGuesser>
);
