//MailAdvertisingTemplatesList
import React from 'react';
import {
    FieldGuesser,
    ListGuesser,
} from "@api-platform/admin";
// import { TextField } from "react-admin";
import {  FunctionField, ReferenceField } from "react-admin";

export const MailAdvertisingTemplatesList = props => {
    return (
        <ListGuesser {...props}>
            <FieldGuesser label="Titel" source={"title"} />
            {/* <TextField label="Thema" source="topic.title" /> */}
            <ReferenceField label="Mail Advertising Topic" source="topic.@id" reference="mail_advertising_topics">
                <FunctionField label="Mail Advertising Topic" render={record => `${record.title} (${record.name})`} />
            </ReferenceField>
            {/* <TextField label="Franchise Partner" source="franchisePartner.companyName" /> */}
            <ReferenceField label="Franchise Partner" source="franchisePartner.@id" reference="franchise_partners">
                <FunctionField label="Franchise Partner" render={record => `${record.companyName} - ${record.firstname} ${record.lastname}`} />
            </ReferenceField>
            {/* <FieldGuesser source={"contentUrl"} /> */}
            {/* <FieldGuesser source={"file"} /> */}
        </ListGuesser>
    )
}