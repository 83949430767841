import { ShowGuesser, FieldGuesser } from "@api-platform/admin";

export const BusinessSectorShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"isPrimary"} addLabel={true} />
        <FieldGuesser source={"searchPattern"} addLabel={true} />
        <FieldGuesser source={"title"} addLabel={true} />
        <FieldGuesser source={"description"} addLabel={true} />
        {/* <FieldGuesser source={"mailAdvertisingTopics"} addLabel={true} /> */}
        {/* <FieldGuesser source={"mailAdvertisingOrders"} addLabel={true} /> */}
        {/* <FieldGuesser source={"companies"} addLabel={true} /> */}
        {/* <FieldGuesser source={"numberOfCompanies"} addLabel={true} /> */}
    </ShowGuesser>
);