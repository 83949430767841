// MailAdvertisingOrderStatusesList
import React from 'react';
import {
    FieldGuesser,
    ListGuesser,
} from "@api-platform/admin";

export const MailAdvertisingOrderStatusesList = props => {
    return (
        <ListGuesser {...props}>
            <FieldGuesser label="Name" source={"name"} />
            <FieldGuesser label="Titel" source={"title"} />
            <FieldGuesser label="Beschreibung" source={"description"} />
            {/* <FieldGuesser source={"mailAdvertisingOrders"} /> */}
        </ListGuesser>
    )
}