import React from 'react';
import {
    FieldGuesser,
    ListGuesser
} from "@api-platform/admin";
import { FunctionField, ReferenceField, TextField } from "react-admin";

export const PlaceList = props => (
    <ListGuesser {...props}>
        <TextField source={"name"} />
        {/*<FieldGuesser source={"coordinates"} />*/}
        {/*<FieldGuesser source={"address"} />*/}
        {/*<FieldGuesser source={"contact"} />*/}
        <ReferenceField label="Franchise Partner" source="franchisePartner" reference="franchise_partners">
            <FunctionField label="Franchise Partner" render={record => `${record.companyName} - ${record.firstname} ${record.lastname}`} />
        </ReferenceField>
    </ListGuesser>
);