import { ShowGuesser, FieldGuesser } from "@api-platform/admin";
import {Datagrid, EditButton, EmailField, ReferenceManyField, ShowButton, TabbedShowLayout, Tab, TextField} from 'react-admin';

// const CountryTitle = ({ record }) => {
//     return <span>Country {record ? `"${record.title}"` : ''}</span>;
// };
//title={<CountryTitle />}
export const CountryShow = props => (
    <ShowGuesser  {...props}>
        <TabbedShowLayout syncWithLocation={true}>
            <Tab label="summary">
                <FieldGuesser source={"code"} addLabel={true} />
                <FieldGuesser source={"title"} addLabel={true} />
            </Tab>
            <Tab label="franchisePartners" path="franchisePartners">
                {/* <FieldGuesser source={"franchisePartners"} addLabel={true} /> */}
                <ReferenceManyField
                        reference="franchise_partners"
                        target="country"
                        label="Franchise Partners"
                    >
                    <Datagrid>
                        <TextField source="companyName" />
                        <TextField source="firstname" />
                        <TextField source="lastname" />
                        <EmailField source="email" />
                        <ShowButton />
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        {/* <FieldGuesser source={"franchiseAreas"} addLabel={true} /> */}
        </TabbedShowLayout>
    </ShowGuesser>
);