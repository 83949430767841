import { useUpdate, Button } from 'react-admin';

const MailAdvertisingOrderStatusUpdateButton = ({ record }) => {
    const [updateStatus, { loading }] = useUpdate('mail_advertising_orders', record.id, {
        status: `/api/mail_advertising_order_statuses/${record.status.nextStatus ? record.status.nextStatus.id : ''}`
    }, record);

    if (!record.status.nextStatus) {
        return '';
    }

    return <Button label={`Als "${record.status.nextStatus.title}" markieren`} onClick={updateStatus} disabled={loading} />;
};

export default  MailAdvertisingOrderStatusUpdateButton;