import { CreateGuesser } from "../../form/CreateGuesser";
import {
    ReferenceArrayInput,
    TextInput
} from 'react-admin';
import { DualListInput } from "@react-admin/ra-relationships";
import RichTextInput from 'ra-input-rich-text';

export const MailAdvertisingTopicCreate = props => (
    <CreateGuesser {...props}>
        <TextInput source={"name"} />
        <TextInput source={"title"} />
        <RichTextInput source={"description"} />
        <ReferenceArrayInput label="Business Sectors" source="businessSectors" reference="business_sectors">
            <DualListInput optionText="title" />
        </ReferenceArrayInput>
    </CreateGuesser>
);