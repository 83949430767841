import { ShowGuesser, FieldGuesser } from "@api-platform/admin";
import {Datagrid, EditButton, ReferenceManyField, RichTextField, ShowButton, TextField} from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';

export const MailAdvertisingTopicShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"name"} addLabel={true} />
        <FieldGuesser source={"title"} addLabel={true} />
        <FieldGuesser source={"description"} addLabel={true} />
        <ReferenceManyField
            reference="business_sectors"
            target="mailAdvertisingTopics"
            label="Business Sectors"
            perPage={10000}
        >
            <Datagrid>
                <TextField source="searchPattern" addLabel={true} />
                <TextField source="title" addLabel={true} />
                <RichTextField source={"description"} addLabel={true} />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </ReferenceManyField>
        {/* <FieldGuesser source={"mailAdvertisingTemplates"} addLabel={true} /> */}
    </ShowGuesser>
);