// UserList
import React from 'react';
import {
    FieldGuesser,
    ListGuesser
} from "@api-platform/admin";
import { EmailField, FunctionField, ReferenceField } from "react-admin";
// import { TextField } from "react-admin";

export const UsersList = props => {
    return (
        <ListGuesser {...props}>
            {/* <EmailField label="User-Identifier" source={"userIdentifier"} /> */}
            {/* <EmailField label="Username" source={"username"} /> */}
            {/* <FieldGuesser source={"roles"} />
            <FieldGuesser source={"password"} /> */}
            <FieldGuesser label="Vorname" source={"firstName"} />
            <FieldGuesser label="Nachname" source={"lastName"} />
            <EmailField label="e-Mail" source={"email"} />
            {/* <FieldGuesser source={"franchisePartner"} /> */}
            <ReferenceField label="Franchise Partner" source="franchisePartner" reference="franchise_partners">
                <FunctionField label="Franchise Partner" render={record => `${record.companyName} - ${record.firstname} ${record.lastname}`} />
            </ReferenceField>
            {/* <FieldGuesser source={"salt"} /> */}
            {/* <ReferenceField label="Title of country" source="country" reference="countries">
                <TextField source="title" />
            </ReferenceField> */}
        </ListGuesser>
    )
}