//MailAdvertisingOrdersList
import React from 'react';
import {
    FieldGuesser,
    ListGuesser,
} from "@api-platform/admin";
import { TextField } from "react-admin";
import MailAdvertisingOrderStatusUpdateButton from './MailAdvertisingOrderStatusUpdateButton';

export const MailAdvertisingOrdersList = props => {
    return (
        <ListGuesser {...props}>
            <FieldGuesser label="Erstellt am" source={"createdAt"} />
            <FieldGuesser label="Aktualisiert am" source={"updatedAt"} />
            <TextField label="Status" source="status.title" />
            <TextField label="Franchise Partner - Firmenname" source="franchisePartner.companyName" />
            <TextField label="Franchise Partner - Vorname" source="franchisePartner.firstname" />
            <TextField label="Franchise Partner - Nachname" source="franchisePartner.lastname" />
            <TextField label="Briefvorlage" source="template.title" />
            {/* <FieldGuesser source={"businessSectors"} />
            <FieldGuesser source={"receivingAddresses"} />
            <FieldGuesser source={"invoiceItems"} /> */}
            <MailAdvertisingOrderStatusUpdateButton />
        </ListGuesser>
    )
}