import {
    number,
    maxValue,
    minValue,
    TextInput
} from 'react-admin';

const validateLatLng = [number(), minValue(-99.9999999), maxValue(99.9999999)];

const LatLngInput = () => (
    <span>
        <TextInput name="coordinates[lat]" placeholder="latitude" validate={validateLatLng} />
        &nbsp;
        <TextInput name="coordinates[lng]" placeholder="longitude" validate={validateLatLng} />
    </span>
);
export default LatLngInput;