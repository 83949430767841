import React from 'react';
import { CreateGuesser, InputGuesser } from "@api-platform/admin";

export const PlaceCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"name"} />
        <InputGuesser source={"coordinates"} />
        <InputGuesser source={"address"} />
        <InputGuesser source={"contact"} />
        <InputGuesser source={"franchisePartner"} />
    </CreateGuesser>
);
