import { CreateGuesser } from "../../form/CreateGuesser";
import { InputGuesser } from "@api-platform/admin";
import {
    ReferenceInput,
    regex,
    SelectInput,
} from 'react-admin';

const validateZipCode = regex(/^\d{4,5}$/, 'Must be a valid Zip Code');

export const FranchiseAreaCreate = props => (
    <CreateGuesser {...props}>
        <ReferenceInput
            source="country"
            reference="countries"
        >
            <SelectInput optionText="title" />
        </ReferenceInput>
        <InputGuesser source={"postcode"} validate={validateZipCode} />
        <ReferenceInput
            source="franchisePartner"
            reference="franchise_partners"
            allowEmpty
        >
            <SelectInput optionText={record => `${record.companyName} - ${record.firstname} ${record.lastname}`} />
        </ReferenceInput>
    </CreateGuesser>
);