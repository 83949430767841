import { EditGuesser } from "../../form/EditGuesser";
import { InputGuesser } from "@api-platform/admin";
import {
    email,
    ReferenceInput,
    regex,
    SelectInput,
    TextInput,
} from 'react-admin';

import LatLngInput from '../../form/field/LatLngInput';

const validateEmail = email();
const validateZipCode = regex(/^\d{4,5}$/, 'Must be a valid Zip Code');

export const FranchisePartnerEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="customerNumber" />
        <InputGuesser source="companyName" />
        <InputGuesser source="firstname" />
        <InputGuesser source="lastname" />
        <TextInput source="email" type="email" validate={validateEmail} />
        <TextInput source={"alternativeEmail"} type="email" validate={validateEmail} />
        <InputGuesser source="phone" />
        <InputGuesser source="street" />
        <InputGuesser source="postcode" validate={validateZipCode} />
        <InputGuesser source="city" />
        <InputGuesser source="state" />
        {/* <InputGuesser source="country" /> */}
        <ReferenceInput
            source="country"
            reference="countries"
            allowEmpty
        >
            <SelectInput optionText="title" />
        </ReferenceInput>
        {/* <InputGuesser source={"coordinates"} /> */}
    </EditGuesser>
);