import { ShowGuesser, FieldGuesser } from "@api-platform/admin";
import { FunctionField, ReferenceField } from 'react-admin';

export const CompanyDataShow = props => (
    <ShowGuesser {...props}>
        <ReferenceField label="Franchise Area" source="franchiseArea" reference="areas">
            <FunctionField label="Franchise Area" render={record => `${record.country} - ${record.postcode} ${record.franchisePartner}`} />
        </ReferenceField>
        <ReferenceField label="Business Sector" source="businessSector" reference="business_sectors">
            <FunctionField label="Franchise Partner" render={record => `${record.searchPattern} - ${record.title}`} />
        </ReferenceField>
        <FieldGuesser source={"bipid"} addLabel={true} />
        <FieldGuesser source={"bipid2"} addLabel={true} />
        <FieldGuesser source={"crf"} addLabel={true} />
        <FieldGuesser source={"zcrf"} addLabel={true} />
        <FieldGuesser source={"salutation"} addLabel={true} />
        <FieldGuesser source={"academicTitle"} addLabel={true} />
        <FieldGuesser source={"firstName"} addLabel={true} />
        <FieldGuesser source={"titleOfNobility"} addLabel={true} />
        <FieldGuesser source={"namePrefix"} addLabel={true} />
        <FieldGuesser source={"lastName"} addLabel={true} />
        <FieldGuesser source={"companyNameLine1"} addLabel={true} />
        <FieldGuesser source={"companyNameLine2"} addLabel={true} />
        <FieldGuesser source={"companyNameLine3"} addLabel={true} />
        <FieldGuesser source={"street"} addLabel={true} />
        <FieldGuesser source={"countryCode"} addLabel={true} />
        <FieldGuesser source={"postcode"} addLabel={true} />
        <FieldGuesser source={"city"} addLabel={true} />
        <FieldGuesser source={"salutationOfALetter"} addLabel={true} />
        <FieldGuesser source={"economicSector1"} addLabel={true} />
        <FieldGuesser source={"economicSector1Text"} addLabel={true} />
        <FieldGuesser source={"economicSector2"} addLabel={true} />
        <FieldGuesser source={"economicSector2Text"} addLabel={true} />
        <FieldGuesser source={"economicSector3"} addLabel={true} />
        <FieldGuesser source={"economicSector3Text"} addLabel={true} />
        <FieldGuesser source={"economicSector4"} addLabel={true} />
        <FieldGuesser source={"economicSector4Text"} addLabel={true} />
        <FieldGuesser source={"economicSector5"} addLabel={true} />
        <FieldGuesser source={"economicSector5Text"} addLabel={true} />
        <FieldGuesser source={"res1"} addLabel={true} />
        <FieldGuesser source={"res2"} addLabel={true} />
        <FieldGuesser source={"res3"} addLabel={true} />
    </ShowGuesser>
);