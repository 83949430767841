// FranchiseAreasList
import React from 'react';
import {
    FieldGuesser,
    ListGuesser,
} from "@api-platform/admin";
import { FunctionField, ReferenceField, TextField } from "react-admin";

export const FranchiseAreasList = props => {
    return (
        <ListGuesser {...props}>
            <ReferenceField label="Land" source="country" reference="countries">
                <TextField source="title" />
            </ReferenceField>
            <FieldGuesser label="PLZ" source={"postcode"} />
            <ReferenceField label="Franchise Partner" source="franchisePartner" reference="franchise_partners">
                <FunctionField label="Franchise Partner" render={record => `${record.companyName} - ${record.firstname} ${record.lastname}`} />
            </ReferenceField>
        </ListGuesser>
    )
}