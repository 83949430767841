import { ShowGuesser, FieldGuesser } from "@api-platform/admin";

export const MailAdvertisingOrderStatusShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"name"} addLabel={true} />
        <FieldGuesser source={"title"} addLabel={true} />
        <FieldGuesser source={"description"} addLabel={true} />
        {/* <FieldGuesser source={"mailAdvertisingOrders"} addLabel={true} /> */}
    </ShowGuesser>
);
