import { ShowGuesser, FieldGuesser } from "@api-platform/admin";
import {FunctionField, ReferenceField, TextField} from 'react-admin';


export const FranchiseAreaShow = props => (
    <ShowGuesser  {...props}>
        <ReferenceField label="Country" source="country" reference="countries">
            <TextField source="title" />
        </ReferenceField>
        <FieldGuesser source={"postcode"} addLabel={true} />
        <ReferenceField label="Franchise Partner" source="franchisePartner" reference="franchise_partners">
            <FunctionField label="Franchise Partner" render={record => `${record.companyName} - ${record.firstname} ${record.lastname}`} />
        </ReferenceField>
    </ShowGuesser>
);