import { EditGuesser } from "../../form/EditGuesser";
import {
    ReferenceArrayInput,
    TextInput
} from "react-admin";
import { DualListInput } from "@react-admin/ra-relationships";
import RichTextInput from "ra-input-rich-text";

export const MailAdvertisingTopicEdit = props => (
    <EditGuesser {...props}>
        <TextInput source={"name"} />
        <TextInput source={"title"} />
        <RichTextInput source={"description"} />
        <ReferenceArrayInput label="Business Sectors" source="businessSectors.id" reference="business_sectors">
            <DualListInput source="mailAdvertisingTopics" optionValue="@id" optionText="title" />
        </ReferenceArrayInput>
    </EditGuesser>
);