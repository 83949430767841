import {
    ArrayField,
    Datagrid,
    DateField,
    FunctionField,
    ListButton,
    Pagination,
    ReferenceField,
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    TopToolbar
} from 'react-admin';

import { MailAdvertisingOrderShowReceivingList } from './MailAdvertisingOrderShowReceivingList';
import MailAdvertisingOrderStatusUpdateButton from './MailAdvertisingOrderStatusUpdateButton';
import { PdfField } from '../../fields/PdfField';

const MailAdvertisingOrderShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <MailAdvertisingOrderStatusUpdateButton record={data} />
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const MailAdvertisingOrderShow = (props) => (
    <Show actions={<MailAdvertisingOrderShowActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="Zusammenfassung">
                <TextField label="Id" source="id" />
                <TextField label="Status" source="status.title" />
                <DateField label="Erstellt am" source="createdAt" />
                <TextField label="Erstellt von" source="createdBy" />
                <DateField label="Aktualisiert am" source="updatedAt" />
                <TextField label="Aktualisiert von" source="updatedBy" />
            </Tab>
            <Tab label="Briefvorlage">
                <TextField label="Briefvorlage" source="template.title" />
                <PdfField source="template.contentUrl" />
            </Tab>
            <Tab label="Branchen">
                <ArrayField label="Branchen" source="businessSectors">
                    <Datagrid>
                        <TextField label="Suchbegriff" source="searchPattern" />
                        <TextField label="Titel" source="title" />
                        <TextField label="Beschreibung" source="description" />
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Empfänger Adressdaten" path="mail_advertising_order_receiving_lists">
                <MailAdvertisingOrderShowReceivingList label="Empfänger Adressdaten" source="receivingList.receivingAddresses" />
            </Tab>
            <Tab label="Rechnungsdaten" path="invoiceItems">
                <ReferenceField label="Rechnungsanschrift" source="franchisePartner" reference="franchise_partners">
                    <FunctionField
                        label="Rechnungsanschrift"
                        render={record => (<>
                            {`${record.companyName}`}<br />
                            {`${record.street}`}<br />
                            {`${record.postcode} ${record.city}`}<br />
                            {`${record.country.title}`}<br />
                        </>)} />
                </ReferenceField>
                <ArrayField label="Rechnungsdaten" source="invoiceItems" pagination={<Pagination/>}>
                    <Datagrid hideFilter={false}>
                        <TextField label="Typ" source="description" />
                        <TextField label="Beschreibung" source="additionalDescription" />
                        <FunctionField label="Betrag" render={record => `${record.amount/100} €`} />
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);