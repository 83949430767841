import { EditGuesser } from "../../form/EditGuesser";
import { InputGuesser } from "@api-platform/admin";
import {
    ReferenceInput,
    SelectInput,
    // TextInput
} from 'react-admin';

export const CompanyDataEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput
            source="franchiseArea"
            reference="areas"
            allowEmpty
        >
            <SelectInput optionText={record => `${record.country} - ${record.postcode} ${record.franchisePartner}`} />
        </ReferenceInput>
        <ReferenceInput
            source="businessSector"
            reference="business_sectors"
            allowEmpty
        >
            <SelectInput optionText={record => `${record.searchPattern} - ${record.title}`} />
        </ReferenceInput>
        <InputGuesser source={"bipid"} />
        <InputGuesser source={"bipid2"} />
        <InputGuesser source={"crf"} />
        <InputGuesser source={"zcrf"} />
        <InputGuesser source={"salutation"} />
        <InputGuesser source={"academicTitle"} />
        <InputGuesser source={"firstName"} />
        <InputGuesser source={"titleOfNobility"} />
        <InputGuesser source={"namePrefix"} />
        <InputGuesser source={"lastName"} />
        <InputGuesser source={"companyNameLine1"} />
        <InputGuesser source={"companyNameLine2"} />
        <InputGuesser source={"companyNameLine3"} />
        <InputGuesser source={"street"} />
        <InputGuesser source={"countryCode"} />
        <InputGuesser source={"postcode"} />
        <InputGuesser source={"city"} />
        <InputGuesser source={"salutationOfALetter"} />
        <InputGuesser source={"economicSector1"} />
        <InputGuesser source={"economicSector1Text"} />
        <InputGuesser source={"economicSector2"} />
        <InputGuesser source={"economicSector2Text"} />
        <InputGuesser source={"economicSector3"} />
        <InputGuesser source={"economicSector3Text"} />
        <InputGuesser source={"economicSector4"} />
        <InputGuesser source={"economicSector4Text"} />
        <InputGuesser source={"economicSector5"} />
        <InputGuesser source={"economicSector5Text"} />
        <InputGuesser source={"res1"} />
        <InputGuesser source={"res2"} />
        <InputGuesser source={"res3"} />
    </EditGuesser>
);