import React from 'react';
import {
    ArrayField,
    Datagrid,
    FunctionField,
    ImageField, NumberField,
    Show,
    SimpleShowLayout,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin';
import {
    BoxedShowLayout,
    RaBox,
    ShowSplitter
} from 'ra-compact-ui';
import { makeStyles } from '@material-ui/core';

import { TimeField } from "../../fields/TimeField";

const useStyles = makeStyles(theme => ({
    addressBox: {
        paddingRight: "50px",
        marginRight: "50px",
    },
}));

export const PlaceShow = props => {
    const classes = useStyles();
    
    return (
        <Show
            {...props}
            component="div"
        >
            <ShowSplitter
                leftSide={
                    <SimpleShowLayout>
                        <TextField source={"name"} label="Place Name" />
                        <ImageField source="franchisePartner.fullImageUrl" title="Picture" />
                        <TextField label="Franchise Partner - Company Name" source="franchisePartner.companyName" />
                        <FunctionField label="Franchise Partner - Fullname" render={record => `${record.franchisePartner.firstname} ${record.franchisePartner.lastname}`} />
                    </SimpleShowLayout>
                }
                rightSide={
                    <TabbedShowLayout>
                        <Tab label="Address">
                            <BoxedShowLayout className={classes.addressBox}>
                                <RaBox display="inline-flex" flexDirection="row" justifyContent="flex-start">
                                    <NumberField source="coordinates.lat" label="Latitude" options={{style: 'decimal', maximumFractionDigits: 7, minimumFractionDigits: 7}} />
                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                                    <NumberField source="coordinates.lng" label="Longitude" options={{style: 'decimal', maximumFractionDigits: 7, minimumFractionDigits: 7}} />
                                </RaBox>
                                <RaBox flex="0 0 100%" display="flex">
                                      <TextField source={"address.street"} addLabel={true} />
                                </RaBox>
                                <RaBox display="inline-flex" flexDirection="row" justifyContent="flex-start">
                                    <TextField source={"address.postcode"} addLabel={true} />
                                    <TextField source={"address.city"} addLabel={true} />
                                </RaBox>
                                <RaBox flex="0 0 100%" display="flex">
                                    <TextField source={"address.state"} addLabel={true} />
                                </RaBox>
                                <RaBox flex="0 0 100%" display="flex">
                                    <TextField source="address.country.title" />
                                </RaBox>
                            </BoxedShowLayout>
                        </Tab>
                        <Tab label="Contact">
                            <TextField source={"contact.email"} addLabel={true} />
                            <TextField source={"contact.phoneNumber"} addLabel={true} />
                            <TextField source={"contact.mobileNumber"} addLabel={true} />
                            <TextField source={"contact.faxNumber"} addLabel={true} />
                        </Tab>
                        <Tab label="Opening Hours Specification">
                            <TextField source="notice" />
                            <ArrayField label="Opening Hours" source="openingHours">
                                <Datagrid>
                                    <TextField label="Day" source="dayOfWeek" />
                                    <TimeField source="opens" locales="de-DE" timeStyle="medium" />
                                    <TimeField source="closes" locales="de-DE" timeStyle="medium" />
                                </Datagrid>
                            </ArrayField>
                        </Tab>
                    </TabbedShowLayout>
                }
            />
        </Show>
    )
};