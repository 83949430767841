import { Layout } from 'react-admin';
import { AppLocationContext } from "@react-admin/ra-navigation";
// import MyAppBar from './MyAppBar';
import AdminAppBar from './AdminAppBar';
import AdminMenu from './AdminMenu';
// import MyNotification from './MyNotification';
import {
    SidebarOpenPreferenceSync
} from '@react-admin/ra-preferences';
import React from "react";

const AdminLayout = (props) => (
    <AppLocationContext hasDashboard={props.hasDashboard}>
        <SidebarOpenPreferenceSync />
        <Layout
            {...props}
            // appBar={MyAppBar}
            appBar={AdminAppBar}
            menu={AdminMenu}
            // notification={MyNotification}
        />
    </AppLocationContext>
);

export default AdminLayout;