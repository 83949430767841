import { ShowGuesser, FieldGuesser } from "@api-platform/admin";
import { FunctionField, ReferenceField } from 'react-admin';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import { makeStyles } from '@material-ui/core';

import { PdfField } from '../../fields/PdfField';

const useStyles = makeStyles(theme => ({
    detailsBox: {
        paddingRight: "50px",
        borderRight: "solid thin",
        marginRight: "50px",
    },
}));

export const MailAdvertisingTemplateShow = props => {
    const classes = useStyles();

    return (
        <ShowGuesser {...props}>
            <BoxedShowLayout>
                <RaBox display="flex" >
                    <RaBox display="flex" flexWrap="wrap" flexGrow={3} className={classes.detailsBox}>
                        <RaBox flex="0 0 40%" display="inline-flex" justifyContent="flex-start">
                            <FieldGuesser source={"title"} addLabel={true} />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="inline-flex" justifyContent="flex-start">
                            <ReferenceField label="Mail Advertising Topic" source="topic.@id" reference="mail_advertising_topics">
                                <FunctionField label="Mail Advertising Topic" render={record => `${record.title} (${record.name})`} />
                            </ReferenceField>
                        </RaBox>
                        <RaBox flex="0 0 30%" display="inline-flex" justifyContent="flex-start">
                            <ReferenceField label="Franchise Partner" source="franchisePartner.@id" reference="franchise_partners">
                                <FunctionField label="Franchise Partner" render={record => `${record.companyName} - ${record.firstname} ${record.lastname}`} />
                            </ReferenceField>
                        </RaBox>
                    </RaBox>
                    <RaBox flex="0 0 50%" display="inline-flex" flexDirection="column" flexGrow={1}>
                        <RaBox display="flex" flexWrap="wrap" flexGrow={1}>
                            <RaBox flex="0 0 100%" display="inline-flex" justifyContent="space-around">
                                <PdfField source="contentUrl" title="title" addLabel={true} />
                            </RaBox>
                        </RaBox>
                    </RaBox>
                </RaBox>
            </BoxedShowLayout>
        </ShowGuesser>
    )
};