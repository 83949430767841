import { CreateGuesser } from "../../form/CreateGuesser";
import { InputGuesser } from "@api-platform/admin";
import {
    email,
    ReferenceInput,
    regex,
    SelectInput,
    TextInput,
    required,
} from 'react-admin';

import LatLngInput from '../../form/field/LatLngInput';

const validateEmail = [required(), email()];
const validateRequired = [required()];
const validateZipCode = [required(), regex(/^\d{5}$/, 'Must be a valid Zip Code')];

export const FranchisePartnerCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="companyName" validate={validateRequired} />
        <InputGuesser source="firstname" validate={validateRequired} />
        <InputGuesser source="lastname" validate={validateRequired} />
        <TextInput source="email" type="email" validate={validateEmail} />
        <TextInput source={"alternativeEmail"} type="email" validate={validateEmail} />
        <InputGuesser source="phone" />
        <InputGuesser source="street" validate={validateRequired} />
        <InputGuesser source="postcode" validate={validateZipCode} />
        <InputGuesser source="city" validate={validateRequired} />
        <InputGuesser source="state" />
        <ReferenceInput
            source="country"
            reference="countries"
            allowEmpty
        >
            <SelectInput optionText="title" />
        </ReferenceInput>
        <LatLngInput source="coordinates" />
    </CreateGuesser>
    );