import * as React from "react";
// import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import Link from '@mui/material/Link';

import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.js`;

export const PdfField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);

    const fileUrl = get(record, source);

    return <>
        <BoxedShowLayout>
            <RaBox display="flex">
                <RaBox flex="0 0 100%" display="inline-flex" flexDirection="column" flexGrow={1}>
                    <RaBox display="flex" flexWrap="wrap" flexGrow={1}>
                        <RaBox flex="0 0 100%" display="inline-flex" justifyContent="space-around">
                            <Document
                                file={fileUrl}
                                // file="/files/Werbebrief_Beschichtungen_SET_Post.pdf"
                                // onLoadSuccess={onDocumentLoadSuccess}
                                options={{
                                    cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                                    cMapPacked: true,
                                }}
                            >
                                <Page pageNumber={1} scale={0.7 }/>
                            </Document>
                        </RaBox>
                        <RaBox flex="0 0 100%" display="inline-flex" justifyContent="space-around" style={{ paddingTop: "25px" }}>
                            <Link href={fileUrl} target="_blank" underline="hover">
                                {'Download'}
                            </Link>
                        </RaBox>
                    </RaBox>
                </RaBox>
            </RaBox>
        </BoxedShowLayout>
    </>;
}