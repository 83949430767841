import React from 'react';
import {
    FieldGuesser,
    ListGuesser
} from "@api-platform/admin";
import { TextField } from "react-admin";
import { CreateDialog } from '@react-admin/ra-form-layout';

import { FranchisePartnerCreate } from './FranchisePartnerCreate';

export const FranchisePartnersList = props => {
    return (
        <>
            <ListGuesser {...props}>
                <TextField label="Debitorennummer" source={"customerNumber"} />
                <FieldGuesser label="Firmenname" source={"companyName"} />
                <FieldGuesser label="Vorname" source={"firstname"} />
                <FieldGuesser label="Nachname" source={"lastname"} />
                <FieldGuesser label="e-Mail" source={"email"} />
                <FieldGuesser label="Telefon" source={"phone"} />
                <FieldGuesser label="Straße" source={"street"} />
                <FieldGuesser label="PLZ" source={"postcode"} />
                <FieldGuesser label="Stadt" source={"city"} />
                <FieldGuesser label="Bundesland" source={"state"} />
                <TextField label="Land" source={"country.title"} />
            </ListGuesser>
            <CreateDialog {...props}>
                <FranchisePartnerCreate />
            </CreateDialog>
        </>
    )
}