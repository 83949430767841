import { EditGuesser } from "../../form/EditGuesser";
import {
    CheckboxGroupInput,
    email,
    PasswordInput,
    ReferenceInput,
    SelectInput,
    TextInput
} from 'react-admin';

const validateEmail = email();

export const UserEdit = props => (
    <EditGuesser {...props}>
        <TextInput source="email" type="email" validate={validateEmail} />
        <TextInput source={"firstName"} />
        <TextInput source={"lastName"} />
        <PasswordInput source={"password"} />
        <CheckboxGroupInput source="roles" choices={[
            { id: 'ROLE_USER', name: 'ROLE_USER' },
            { id: 'ROLE_PARTNER_USER', name: 'ROLE_PARTNER_USER' },
            { id: 'ROLE_PARTNER_MARKETING', name: 'ROLE_PARTNER_MARKETING' },
            { id: 'ROLE_PARTNER_ADMIN', name: 'ROLE_PARTNER_ADMIN' },
            { id: 'ROLE_HEADQUARTERS_USER', name: 'ROLE_HEADQUARTERS_USER' },
            { id: 'ROLE_HEADQUARTERS_MARKETING', name: 'ROLE_HEADQUARTERS_MARKETING' },
            { id: 'ROLE_HEADQUARTERS_ADMIN', name: 'ROLE_HEADQUARTERS_ADMIN' },
            { id: 'ROLE_ADMIN', name: 'ROLE_ADMIN' },
            { id: 'ROLE_SUPER_ADMIN', name: 'ROLE_SUPER_ADMIN' },
        ]} />
        <ReferenceInput
            source="franchisePartner"
            reference="franchise_partners"
            allowEmpty
        >
            <SelectInput optionText={record => `${record.companyName} - ${record.firstname} ${record.lastname}`} />
        </ReferenceInput>
    </EditGuesser>
);