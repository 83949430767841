import React from 'react'
import {
    FieldGuesser,
    ListGuesser
} from "@api-platform/admin";

export const CountriesList = (props) => {
    return (
        <ListGuesser {...props}>
            <FieldGuesser label="Länder-Code" source="code" />
            <FieldGuesser label="Titel" source="title" />
        </ListGuesser>
    )
}
