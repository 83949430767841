import { ShowGuesser, FieldGuesser } from "@api-platform/admin";
import {
    Datagrid,
    EditButton,
    EmailField,
    FunctionField,
    ImageField,
    NumberField,
    ReferenceField,
    ReferenceManyField,
    SimpleShowLayout,
    ShowButton,
    TabbedShowLayout,
    Tab,
    TextField
} from 'react-admin';
import { BoxedShowLayout, RaBox, ShowSplitter } from 'ra-compact-ui';
import { makeStyles } from '@material-ui/core';

import LocationsMap from '../../LocationsMap';
// import { GMapField } from '@fusionworks/ra-google-maps-input';

const useStyles = makeStyles(theme => ({
    detailsBox: {
        paddingRight: "50px",
        borderRight: "solid thin",
        marginRight: "50px",
    },
}));

export const FranchisePartnerShow = props => {
    const classes = useStyles();

    return (
        <ShowGuesser {...props}>
            <ShowSplitter
                leftSide={
                    <SimpleShowLayout>
                        <ImageField source="fullImageUrl" title="Picture" />
                        <TextField source={"customerNumber"} addLabel={true} />
                        <FieldGuesser source={"companyName"} addLabel={true} />
                        <FieldGuesser source={"firstname"} addLabel={true} />
                        <FieldGuesser source={"lastname"} addLabel={true} />
                    </SimpleShowLayout>
                }
                rightSide={
                    <TabbedShowLayout>
                        <Tab label="contact">
                            <FieldGuesser source={"phone"} addLabel={true} />
                            <EmailField source={"email"} addLabel={true} />
                            <EmailField source={"alternativeEmail"} addLabel={true} />
                        </Tab>
                        <Tab label="address" path="address">
                            <BoxedShowLayout>
                                <RaBox display="flex" >
                                    <RaBox display="flex" flexWrap="wrap" flexGrow={2} className={classes.detailsBox}>
                                        <RaBox flex="0 0 100%" display="inline-flex" justifyContent="flex-start">
                                            <FieldGuesser source={"street"} addLabel={true} />
                                        </RaBox>
                                        <RaBox flex="0 0 50%" display="inline-flex" justifyContent="flex-start">
                                            <FieldGuesser source={"postcode"} addLabel={true} />
                                        </RaBox>
                                        <RaBox flex="0 0 50%" display="inline-flex" justifyContent="flex-start">
                                            <FieldGuesser source={"city"} addLabel={true} />
                                        </RaBox>
                                        <RaBox flex="0 0 50%" display="inline-flex" justifyContent="flex-start">
                                            <FieldGuesser source={"state"} addLabel={true} />
                                        </RaBox>
                                        <RaBox flex="0 0 50%" display="inline-flex" justifyContent="flex-start">
                                            <TextField source="country.title" />
                                        </RaBox>
                                        <RaBox flex="0 0 50%" display="inline-flex" justifyContent="flex-start">
                                            <NumberField source="coordinates.lat" label="Latitude" options={{style: 'decimal', maximumFractionDigits: 7, minimumFractionDigits: 7}} />
                                        </RaBox>
                                        <RaBox flex="0 0 50%" display="inline-flex" justifyContent="flex-start">
                                            <NumberField source="coordinates.lng" label="Longitude" options={{style: 'decimal', maximumFractionDigits: 7, minimumFractionDigits: 7}} />
                                        </RaBox>
                                    </RaBox>
                                    <RaBox flex="0 0 50%" display="inline-flex" flexDirection="column" flexGrow={1}>
                                        <div style={{ height: 450, width: '100%' }}>
                                            <FunctionField label="Map" render={record => <LocationsMap locations={[record]} />} />
                                        </div>
                                        {/* <GMapField
                                            source="coordinates"
                                            googleKey="AIzaSyCxTS1yLBYBj_etlvK5NcFu5qnLJWuqqiU"
                                            zoom={5.5} 
                                        />*/}
                                    </RaBox>
                                </RaBox>
                            </BoxedShowLayout>
                        </Tab>
                        <Tab label="areas" path="areas">
                            <ReferenceManyField
                                    reference="areas"
                                    target="franchisePartner"
                                    label="Franchise Areas"
                                    perPage={10000}
                                >
                                <Datagrid>
                                    <ReferenceField label="Country" source="country" reference="countries">
                                        <TextField source="title" />
                                    </ReferenceField>
                                    <TextField source="postcode" />
                                    <ShowButton />
                                    <EditButton />
                                </Datagrid>
                            </ReferenceManyField>
                        </Tab>
                        <Tab label="users" path="users">
                            <ReferenceManyField
                                    reference="users"
                                    target="franchisePartner"
                                    label="Users"
                                    perPage={10000}
                                >
                                <Datagrid>
                                    <TextField source="firstName" />
                                    <TextField source="lastName" />
                                    <EmailField source={"email"} addLabel={true} />
                                    {/* <FieldGuesser source={"roles"} addLabel={true} /> */}
                                    <ShowButton />
                                    <EditButton />
                                </Datagrid>
                            </ReferenceManyField>
                        </Tab>
                        <Tab label="Marketing Info" path="marketing-info">
                            <TabbedShowLayout>
                                <Tab label="Mail Advertising Orders">
                                    {/* mail_advertising_orders */}
                                    <ReferenceManyField
                                        reference="mail_advertising_orders"
                                        target="franchisePartner"
                                        label="Mail Advertising Orders"
                                    >
                                        <Datagrid>
                                            <FieldGuesser label="Erstellt am" source={"createdAt"} />
                                            <FieldGuesser label="Aktualisiert am" source={"updatedAt"} />
                                            <TextField label="Status" source="status.title" />
                                            <TextField label="Briefvorlage" source="template.title" />
                                            <ShowButton />
                                        </Datagrid>
                                    </ReferenceManyField>

                                </Tab>
                                <Tab label="Mail Advertising Templates" path="marketing-info.mail_advertising_templates">
                                    {/* <ReferenceManyField
                                        reference="mail_advertising_templates"
                                        target="franchisePartner"
                                        label="Mail Advertising Templates"
                                    >
                                        <Datagrid>
                                            <TextField label="Titel" source={"title"} />
                                            <ReferenceField label="Mail Advertising Topic" source="topic.@id" reference="mail_advertising_topics">
                                                <FunctionField label="Mail Advertising Topic" render={record => `${record.title} (${record.name})`} />
                                            </ReferenceField>
                                            <ShowButton />
                                        </Datagrid>
                                    </ReferenceManyField> */}
                                </Tab>
                            </TabbedShowLayout>
                        </Tab>
                        <Tab label="settings" path="settings">
                            <FieldGuesser source={"showOnHomepage"} addLabel={true} />
                        </Tab>
                    </TabbedShowLayout>
                }
            />
        </ShowGuesser>
    );
};