// in src/Dashboard.js
import * as React from "react";
import {Card, CardContent, Typography} from '@material-ui/core';
import { Title } from 'react-admin';

const AdminLayout = () => (
    <Card>
        <Title title="Willkommen in der bazuba Admin App" />
        <CardContent>
            <Typography variant="h5">Willkommen in der bazuba Admin App</Typography>
            <br />
            <br />
            <br />
        </CardContent>
    </Card>
);

export default AdminLayout;