import { ShowGuesser, FieldGuesser } from "@api-platform/admin";
import {EmailField, FunctionField, ReferenceField, TextField} from 'react-admin';

export const UserShow = props => (
    <ShowGuesser {...props}>
        {/* <EmailField source={"userIdentifier"} addLabel={true} /> */}
        <EmailField source={"username"} addLabel={true} />
        <TextField source={"firstName"} addLabel={true} />
        <TextField source={"lastName"} addLabel={true} />
        <EmailField source={"email"} addLabel={true} />
        <FieldGuesser source={"roles"} addLabel={true} />
        <ReferenceField label="Franchise Partner" source="franchisePartner" reference="franchise_partners">
            <FunctionField label="Franchise Partner" render={record => `${record.companyName} - ${record.firstname} ${record.lastname}`} />
        </ReferenceField>
    </ShowGuesser>
);
