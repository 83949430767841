import { CreateGuesser } from "../../form/CreateGuesser";
import { InputGuesser } from "@api-platform/admin";
import {
    regex,
    required,
} from 'react-admin';

const validateCountryIsoCode = [required(), regex(/^[A-Z]{2}$/, 'Country code must contain exact two uppercase letters')];
const validateTitle = [required()];

export const CountryCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"code"} validate={validateCountryIsoCode} />
        <InputGuesser source={"title"} validate={validateTitle} />
    </CreateGuesser>
);