import React from "react";
import { Redirect, Route } from "react-router-dom";
import merge from 'lodash/merge';
// @react-admin
import { PreferencesBasedThemeProvider } from '@react-admin/ra-preferences';
import { useDefineAppLocation, theme as navigationTheme } from '@react-admin/ra-navigation';
import { buildI18nProvider, buildThemeFromTypeMethod } from '@react-admin/ra-enterprise';
import germanMessages from 'ra-language-german';
// import {
//     AppLocationContext,
//     Breadcrumb,
//     ResourceBreadcrumbItems,
//     useDefineAppLocation,
// } from "@react-admin/ra-navigation";
// @api-platform
import {
    HydraAdmin,
    hydraDataProvider as baseHydraDataProvider,
    fetchHydra as baseFetchHydra,
    useIntrospection,
    ResourceGuesser
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";

// project imports
import authProvider from "./authProvider";
import * as Components from "./components";
import Login from "./components/admin/Login";
import Dashboard from './Dashboard';
import AdminLayout from './layout/AdminLayout';


// import {reducer as tree} from "@react-admin/ra-tree/esm/src/reducer";
// import {reducer as locks} from "@react-admin/ra-realtime";

const ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT;

// todo Waiting for https://github.com/api-platform/admin/issues/372
const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};
const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });
const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login" />;
};
const apiDocumentationParser = async () => {
    try {
        const { api } = await parseHydraDocumentation(ENTRYPOINT, { headers: getHeaders });
        return { api };
    } catch (result) {
        if (result.status !== 401) {
            throw result;
        }

        // Prevent infinite loop if the token is expired
        localStorage.removeItem("token");

        // HACK / FIX for not working redirect to login page
        window.location.replace('/#/login');

        return {
            api: result.api,
            customRoutes: [
                <Route path="/" component={RedirectToLogin} />
            ],
        };
    }
};
const dataProvider = baseHydraDataProvider(
    ENTRYPOINT,
    fetchHydra,
    apiDocumentationParser,
    true // useEmbedded parameter
);

const messages = {
    de: germanMessages,
    //at: austrianMessages,
    //at-vie: austrienViennaMessages,
};
const i18nProvider = buildI18nProvider(messages);

const UserPreferences = () => {
    useDefineAppLocation("myhome.user.preferences");
    return <span>My Preferences</span>;
};

const routes = [
    <Route exact path="/user/preferences" component={UserPreferences} />,
];

const AdminLoader = (props) => {
    const {
        //customReducers,
        customRoutes,
        darkTheme,
        i18nProvider,
        lightTheme,
        theme,
        ...rest
    } = props;

    // const mergedCustomReducers = merge(
    //     {},
    //     {
    //         tree,
    //         locks,
    //     },
    //     customReducers
    // );

    return <PreferencesBasedThemeProvider
            themeFromType={buildThemeFromTypeMethod(
                merge({}, navigationTheme, theme),
                lightTheme,
                darkTheme
            )}
        >
            <HydraAdmin
                dataProvider={dataProvider}
                authProvider={authProvider}
                entrypoint={ENTRYPOINT}
                loginPage={Login}
                dashboard={Dashboard}
                title={process.env.REACT_APP_WEBSITE_NAME}
                disableTelemetry
                layout={AdminLayout}
                i18nProvider={i18nProvider || buildI18nProvider()}
                customRoutes={customRoutes}
                // customReducers={mergedCustomReducers}
                {...rest}
            >
                {/* <ResourceGuesser
                    name="mail_advertising_orders"
                    list={MailAdvertisingOrdersList}
                /> */}
                {/* <ResourceGuesser name="franchise_partners" /> */}

                <ResourceGuesser
                    name={"franchise_partners"}
                    options={{ label: 'Franchise Partner' }}
                    list={Components.FranchisePartnersList}
                    create={Components.FranchisePartnerCreate}
                    edit={Components.FranchisePartnerEdit}
                    show={Components.FranchisePartnerShow} />
                <ResourceGuesser
                    name={"users"}
                    options={{ label: 'User / Mitarbeiter' }}
                    list={Components.UsersList}
                    show={Components.UserShow}
                    create={Components.UserCreate}
                    edit={Components.UserEdit} />
                <ResourceGuesser
                    name={"areas"}
                    options={{ label: 'Franchise Gebiete' }}
                    list={Components.FranchiseAreasList}
                    show={Components.FranchiseAreaShow}
                    create={Components.FranchiseAreaCreate}
                    edit={Components.FranchiseAreaEdit} />
                <ResourceGuesser
                    name={"places"}
                    options={{ label: 'Standorte' }}
                    list={Components.PlaceList}
                    show={Components.PlaceShow}
                    create={Components.PlaceCreate}
                    edit={Components.PlaceEdit}
                />

                <ResourceGuesser
                    name={"mail_advertising_orders"} options={{ label: 'Briefwerbung - Bestellungen' }}
                    list={Components.MailAdvertisingOrdersList}
                    show={Components.MailAdvertisingOrderShow}
                    create={null}
                    delete={null}
                    edit={null}/>
                <ResourceGuesser
                    name={"mail_advertising_templates"}
                    options={{ label: 'Briefwerbung - Briefvorlagen' }}
                    list={Components.MailAdvertisingTemplatesList}
                    show={Components.MailAdvertisingTemplateShow}
                    create={Components.MailAdvertisingTemplateCreate}
                    edit={Components.MailAdvertisingTemplateEdit} />
                <ResourceGuesser
                    name={"mail_advertising_topics"}
                    options={{ label: 'Briefwerbung - Themen' }}
                    list={Components.MailAdvertisingTopicsList}
                    show={Components.MailAdvertisingTopicShow}
                    create={Components.MailAdvertisingTopicCreate}
                    edit={Components.MailAdvertisingTopicEdit}
                    delete={null} />
                <ResourceGuesser
                    name={"mail_advertising_order_statuses"}
                    options={{ label: 'Briefwerbung - Status' }}
                    list={Components.MailAdvertisingOrderStatusesList}
                    show={Components.MailAdvertisingOrderStatusShow}
                    create={null}
                    delete={null}
                    edit={null} />

                <ResourceGuesser
                    name={"company_datas"}
                    options={{ label: 'Firmendaten - Adressdaten' }}
                    list={Components.CompanyDatasList}
                    show={Components.CompanyDataShow}
                    create={Components.CompanyDataCreate}
                    edit={Components.CompanyDataEdit} />
                <ResourceGuesser
                    name={"business_sectors"}
                    options={{ label: 'Firmendaten - Branchen' }}
                    list={Components.BusinessSectorsList}
                    show={Components.BusinessSectorShow}
                    create={Components.BusinessSectorCreate}
                    edit={Components.BusinessSectorEdit} />

                {/* <CountriesResourceGuesser /> */}
                <ResourceGuesser
                    name={"countries"}
                    options={{ label: 'Einstellungen - Länder' }}
                    list={Components.CountriesList}
                    create={Components.CountryCreate}
                    edit={Components.CountryEdit}
                    show={Components.CountryShow} />

                <ResourceGuesser
                    name={"media_objects"}
                    options={{ label: 'Media Files' }}
                    create={Components.MediaObjectCreate} />
            </HydraAdmin>
        </PreferencesBasedThemeProvider>;
};

const Admin = () => (
    <>
        <AdminLoader i18nProvider={i18nProvider} customRoutes={routes} />
    </>
);
export default Admin;