import * as React from "react";
import { Labeled } from 'react-admin';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const columns = [
    { field: 'companyNameLine1', flex: 1 },
    { field: 'companyNameLine2', flex: 1 },
    { field: 'companyNameLine3', flex: 1 },
    { field: 'street', flex: 1 },
    { field: 'countryCode', flex: 1 },
    { field: 'postcode', flex: 1 },
    { field: 'city', flex: 1 },
    { field: 'salutation', flex: 1 },
    { field: 'academicTitle', flex: 1 },
    { field: 'titleOfNobility', flex: 1 },
    { field: 'namePrefix', flex: 1 },
    { field: 'firstName', flex: 1 },
    { field: 'lastName', flex: 1 },
    { field: 'salutationOfALetter', flex: 1 },
];

export const MailAdvertisingOrderShowReceivingList = (props) => {
    const { source, label } = props;
    const record = useRecordContext(props);

    const receivingAddresses = get(record, source);
    
    const receivingAddressesWithIds = receivingAddresses.map((receivingAddress, id) => ({
            id: id,
            ...receivingAddress
        }));

    return <>
        <Labeled label={label} fullWidth>
            <DataGrid
                // getRowId={(row) => JSON.stringify(row)}
                rows={receivingAddressesWithIds}
                columns={columns}
                autoHeight={true}
                density="compact"
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </Labeled>
    </>;
}