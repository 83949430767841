import {
    CreateGuesser,
} from "../form/CreateGuesser";
import { FileField, FileInput } from "react-admin";

export const MediaObjectCreate = props => (
    <CreateGuesser {...props}>
        <FileInput source="file">
            <FileField source="src" title="title" />
        </FileInput>
    </CreateGuesser>
);