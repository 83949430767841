import * as React from "react";
// import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';

export const TimeField = (props) => {
    const { source, locale, timeStyle } = props;
    const record = useRecordContext(props);

    const dateTime = new Date(get(record, source));

    const dateTimeFormatter = new Intl.DateTimeFormat(locale, { timeStyle: timeStyle });

    return <>
        <span>{dateTimeFormatter.format(dateTime)}</span>
    </>;
}