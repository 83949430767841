import { CreateGuesser } from "../../form/CreateGuesser";
import { InputGuesser } from "@api-platform/admin";
import {
    ReferenceArrayInput,
    TextInput
} from 'react-admin';
import { DualListInput } from "@react-admin/ra-relationships";
import RichTextInput from 'ra-input-rich-text';

export const BusinessSectorCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"isPrimary"} />
        <TextInput source={"title"} />
        <TextInput source={"searchPattern"} />
        <RichTextInput source={"description"} />
        <ReferenceArrayInput label="Mail Advertising Topics" source="mailAdvertisingTopics" reference="mail_advertising_topics">
            <DualListInput optionText="title" />
        </ReferenceArrayInput>
    </CreateGuesser>
);