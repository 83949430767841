import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import LoadingIndicator from './LoadingIndicator';

const containerStyle = {
    width: '100%',
    height: '450px'
};

const center = {
    lat: 50.4508289,
    lng: 10.396404
};

function LocationsMap({locations}) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        // googleMapsApiKey: "AIzaSyCVJ5VQgwGbwhe3fpv_yhInVvj_hzjdqPs"
        googleMapsApiKey: "AIzaSyCxTS1yLBYBj_etlvK5NcFu5qnLJWuqqiU"
    })

    // eslint-disable-next-line
    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // const bounds = new window.google.maps.LatLngBounds();
        // map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const renderMap = () => {
        return <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={5.5}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            {locations.map((location) => (
                <Marker key={location.id} id={location.id} position={location.coordinates} />//onClick={alert(location.id, 'clicked')} />
            ))}
        </GoogleMap>
    }

    return isLoaded ? renderMap() : <LoadingIndicator />
}

export default React.memo(LocationsMap);