//CompanyDatasList
import React from 'react'
import {
    FieldGuesser,
    ListGuesser
} from "@api-platform/admin";
import { TextField } from "react-admin";

export const CompanyDatasList = (props) => {
    return (
        <ListGuesser {...props}>
            {/* <FieldGuesser label="" source={"franchiseArea"} />
            <FieldGuesser label="" source={"businessSector"} /> */}
            <FieldGuesser label="bipid" source={"bipid"} />
            <FieldGuesser label="bipid2" source={"bipid2"} />
            <FieldGuesser label="crf" source={"crf"} />
            {/* <FieldGuesser label="zcrf" source={"zcrf"} /> */}
            {/* <FieldGuesser label="Anrede" source={"salutation"} />
            <FieldGuesser label="Akademischer Titel" source={"academicTitle"} />
            <FieldGuesser label="Vorname" source={"firstName"} />
            <FieldGuesser label="Adelstitel" source={"titleOfNobility"} />
            <FieldGuesser label="Namensprefix" source={"namePrefix"} />
            <FieldGuesser label="Nachname" source={"lastName"} /> */}
            <FieldGuesser label="Unternehmensname Zeile 1" source={"companyNameLine1"} />
            <FieldGuesser label="Unternehmensname Zeile 2" source={"companyNameLine2"} />
            <FieldGuesser label="Unternehmensname Zeile 3" source={"companyNameLine3"} />
            <FieldGuesser label="Straße" source={"street"} />
            <FieldGuesser label="Ländercode" source={"countryCode"} />
            <FieldGuesser label="PLZ" source={"postcode"} />
            <FieldGuesser label="Stadt" source={"city"} />
            {/* <FieldGuesser label="Briefanrede" source={"salutationOfALetter"} /> */}
            <TextField label="Business Branche 1" source={"economicSector1"} />
            <FieldGuesser label="Business Branche 1 Text" source={"economicSector1Text"} />
            {/* <TextField label="Business Branche 2" source={"economicSector2"} />
            <FieldGuesser label="Business Branche 2 Text" source={"economicSector2Text"} />
            <TextField label="Business Branche 3" source={"economicSector3"} />
            <FieldGuesser label="Business Branche 3 Text" source={"economicSector3Text"} />
            <TextField label="Business Branche 4" source={"economicSector4"} />
            <FieldGuesser label="Business Branche 4 Text" source={"economicSector4Text"} />
            <TextField label="Business Branche 5" source={"economicSector5"} />
            <FieldGuesser label="Business Branche 5 Text" source={"economicSector5Text"} />
            <FieldGuesser label="res1" source={"res1"} />
            <FieldGuesser label="res2" source={"res2"} />
            <FieldGuesser label="res3" source={"res3"} /> */}
        </ListGuesser>
    )
}
